@font-face {
  font-family: 'DM Sans';
  font-weight: 400;
  font-style: normal;
  src: url('./assets/fonts/DMSans-Regular.ttf');
}

@font-face {
  font-family: 'DM Sans';
  font-weight: 500;
  font-style: normal;
  src: url('./assets/fonts/DMSans-Medium.ttf');
}

@font-face {
  font-family: 'DM Sans';
  font-weight: 700;
  font-style: normal;
  src: url('./assets/fonts/DMSans-Bold.ttf');
}

* {
  font-family: 'DM Sans','Helvetica Neue',sans-serif !important;
}

html, body, #root {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}
